// reviewer page

import './reviewer.css'

import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Utilities from '../js/utils';

function Reviewer() {

    // determine song and album id
    var args = window.location.pathname.split("/");
    var albumID = args[2];
    var songID = args[3];

    if(parseInt(albumID) == NaN || albumID == undefined) {
        // redirect
        window.location.href = "/albumReviewDir/";
    } else if (parseInt(songID) == NaN || songID == undefined) {
        window.location.href = "/albumReviewDir/" + albumID;
    }

    var [reviewData, setReviewData] = useState(
        {
            "name": "Song Name",
            "album_name": "Album Name",
            "long_review": "",
            "rating": 0,
            "metric_lyrics": -1,
            "metric_melody": -1,
            "metric_engineering": -1,
            "metric_uniqueness": -1,
            "metric_story_telling": -1,
            "quotes": []
        }
    );

    function addQuote() {
        var newReviewData = { ...reviewData };
        newReviewData.quotes.push("");

        setReviewData(newReviewData);
    }

    function deleteQuote(event) {
        var newReviewData = { ...reviewData };
        newReviewData.quotes = [];

        for(var i = 0; i < reviewData.quotes.length; i++) {
            if(i != event.target.dataset.key) {
                newReviewData.quotes.push(reviewData.quotes[i]);
            }
        }

        setReviewData(newReviewData);
    }

    function updateQuote(event) {

        var newReviewData = { ...reviewData };
        newReviewData.quotes[event.target.dataset.key] = event.target.value;

        setReviewData(newReviewData);
    }

    function updateValue(event, metricName) {

        var newReviewData = { ...reviewData };
        newReviewData[metricName] = event.target.value;

        setReviewData(newReviewData);
    }

    function submitSongReview(event) {
        event.preventDefault();

        const utils = new Utilities();
        const formData = new FormData(event.target);

        let data = {}
        for (const pair of formData.entries()) {
            if (pair[0].includes("quotes") == true) {
                if (data['quotes'] == undefined || data['quotes'].length <= 0) {
                    data['quotes'] = [];
                }
                data['quotes'].push(pair[1]);
            } else {
                data[pair[0]] = pair[1];
            }
        }

        // get csrf token
        var csrfToken = utils.parseCookie('csrftoken');

        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken
        }

        axios.post(
            "/api/submitSongReview",
            data,
            { headers }
        ).then((response) => {
            console.log(response);
        });
    }

    useEffect(() => {
        // get review data
        if(parseInt(albumID) > 0 && parseInt(songID) > 0 ) {
            axios.get('/api/songReview/' + albumID + '/' + songID).then((response) => {
                if (response.status == 200 && response.data.success == true && response.data.results > 0) {
                    setReviewData(response.data.result);
                }
            });
        }
    }, []);


    // generate quotes input
    var quoteRender = [];
    if (reviewData.quotes.length > 0) {
        for (var x in reviewData.quotes) {
            quoteRender.push(
                <div key={x} className="quoteDiv">
                    <input style={{width: '80%'}} name={"quotes_" + x} data-key={x} value={reviewData.quotes[x]} onChange={updateQuote}/>
                    <button type="button" data-key={x} onClick={deleteQuote}>-</button>
                </div>
            );
        }
    }

    return (
        <>  
            <section className='reviewer'>
                <a style={{}} href={"/albumReviewDir/" + albumID}>Back</a>
                { reviewData.album_name == "Album Name" ? undefined : <img className="albumCover" style={{maxWidth: '500px'}} src={"/images/albumCovers/" + reviewData.album_name.toLowerCase() + ".jpeg"}/>}
                <form className='songReview' onSubmit={submitSongReview}>
                    <input type="hidden" name="album_id" value={albumID}/>
                    <input type="hidden" name="song_id" value={songID}/>
                    <h3>{reviewData.album_name}: <span style={{fontWeight: 'normal'}}>{reviewData.name}</span></h3>
                    <div className="numRating">
                        <label className="ratingLabel" htmlFor="metric_lyrics">Lyrics</label>
                        <input name="metric_lyrics" placeholder='0' value={reviewData.metric_lyrics} onChange={(e) => updateValue(e, "metric_lyrics")}/>
                    </div>
                    <div className="numRating">
                        <label className="ratingLabel" htmlFor="metric_melody">Melody</label>
                        <input name="metric_melody" placeholder='0' value={reviewData.metric_melody} onChange={(e) => updateValue(e, "metric_melody")}/>
                    </div>
                    <div className="numRating">
                        <label className="ratingLabel" htmlFor="metric_engineering">Engineering</label>
                        <input name="metric_engineering" placeholder='0' value={reviewData.metric_engineering} onChange={(e) => updateValue(e, "metric_engineering")}/>
                    </div>
                    <div className="numRating">
                        <label className="ratingLabel" htmlFor="metric_uniqueness">Uniqueness</label>
                        <input name="metric_uniqueness" placeholder='0' value={reviewData.metric_uniqueness} onChange={(e) => updateValue(e, "metric_uniqueness")}/>
                    </div>
                    <h3>Extra Points</h3>
                    <div className="numRating">
                        <label className="ratingLabel" htmlFor="metric_story_telling">Story Telling</label>
                        <input name="metric_story_telling" placeholder='0' value={reviewData.metric_story_telling} onChange={(e) => updateValue(e, "metric_story_telling")}/>
                    </div>
                    <label htmlFor="review">Review</label>
                    <textarea className="review" name="review" placeholder='Enter text review' value={reviewData.long_review == null ? "" : reviewData.long_review } onChange={(e) => updateValue(e, "long_review")}/>
                    <label>Fav. Quotes</label>
                    {quoteRender}
                    <button className="newQuoteBtn" onClick={addQuote} type='button'>+</button>
                    <button type="submit">Submit</button>
                </form>
                <footer style={{ "display": "flex", "justifyContent": "space-between", "marginTop": "10px"}}>
                    { reviewData.track_number > 1 ? <Link to={"/reviewer/" +albumID + "/" + (reviewData.track_number - 1)} target='_blank'>Previous Track</Link> : undefined }
                    { reviewData.track_number < reviewData.max_track_count ? <Link to={"/reviewer/" +albumID + "/" + (reviewData.track_number + 1)} target='_blank'>Next Track</Link> : undefined}
                </footer>
            </section>
        </>
    );
}

export default Reviewer;