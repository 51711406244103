import './App.css';

import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// import components
import Home from './components/home.js';
import AlbumReview from './components/albumReview.js';
import Reviewer from './components/reviewer.js';
import ReviewDir from './components/albumReviewDir.js';
import FinalReviewer from './components/finalReviewer.js';

function App() {
  return (
    <BrowserRouter>
      <nav className="hero">
        <Link to="/">
            <h3>Muse Roads</h3>
        </Link>
      </nav>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/albumReview/*" element={<AlbumReview/>}></Route>
        <Route path="/reviewer/*" element={<Reviewer/>}></Route>
        <Route path="/albumReviewDir/*" element={<ReviewDir/>}></Route>
        <Route path="/finalReviewer/*" element={<FinalReviewer/>}></Route>
        <Route path="*" element={<Home/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
