// album review

// import css
import './albumReview.css';

import React from "react";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';

function AlbumReview() {
    // init vars
    var render = [];
    var albumid = window.location.pathname.split('/')[2];
    var [albumReview, setAlbumReview] = useState(undefined);
    var metatags = undefined;

    // fetch album review statdata
    useEffect(() => {
        axios.get('/api/albumReview/' + albumid).then((response) => {
            if (response.status == 200 && response.data.success == true && response.data.results > 0) {
                setAlbumReview(response.data.result);
            }
        })
    }, []);

    // generate loading render
    if (albumReview == undefined) {
        render =  (
            <article className="albumReviewFull">
                <div className="albumInfo">
                    <div className='pendingContentBlock' style={{'width' : '100px', 'height': '30px'}}></div>
                    <span>•</span>
                    <div className='pendingContentBlock' style={{'width' : '100px', 'height': '30px'}}></div>
                    <span>•</span>
                    <div className='pendingContentBlock' style={{'width' : '100px', 'height': '30px'}}></div>
                </div>
                <div className="albumCover">
                    <div className='pendingContentBlock' style={{'width' : '100%', 'height': '200px'}}></div>
                </div>
                <div className="releaseDate">
                    <div className='pendingContentBlock' style={{'width' : '60%', 'height': '30px'}}></div>
                </div>
                <div className='"content'>
                    <div className='pendingContentBlock' style={{'width' : '100%', 'height': '200px'}}></div>
                </div>
                <div className='pendingContentBlock' style={{'margin': 'auto', 'width' : '50%', 'height': '30px'}}></div>
                <section className="trackList">
                    <div className="trackDetails">
                        <div className='pendingContentBlock' style={{'height': '30px'}}></div>
                        <div className='pendingContentBlock' style={{'height': '30px'}}></div>
                        <div className='pendingContentBlock' style={{'height': '30px'}}></div>
                    </div>
                    <div className="trackDetails">
                        <div className='pendingContentBlock' style={{'height': '30px'}}></div>
                        <div className='pendingContentBlock' style={{'height': '30px'}}></div>
                        <div className='pendingContentBlock' style={{'height': '30px'}}></div>
                    </div>
                    <div className="trackDetails">
                        <div className='pendingContentBlock' style={{'height': '30px'}}></div>
                        <div className='pendingContentBlock' style={{'height': '30px'}}></div>
                        <div className='pendingContentBlock' style={{'height': '30px'}}></div>
                    </div>
                </section>
                <section className="albumLinks">
                    <div className='pendingContentBlock' style={{'margin': 'auto', 'width': '30%', maxWidth : '200px', 'height': '30px'}}></div>
                    <div className='pendingContentBlock' style={{'margin': 'auto', 'width': '30%', maxWidth : '200px', 'height': '30px'}}></div>
                    <div className='pendingContentBlock' style={{'margin': 'auto', 'width': '30%', maxWidth : '200px', 'height': '30px'}}></div>
                </section>
            </article>
        );
    } else {

        // generate thumbnails 
        var albumLinks = [];
        var iter = 0;
        for (let thumbnail in albumReview.links) {
            if(thumbnail == "spotify") {
                albumLinks.push(
                    <Link className="genLink btn" key={iter} to={albumReview.links[thumbnail]} target='_blank'><img className="albumLinks" src={"/images/icons/spotify.png"} alt="Spotify Link"></img>Spotify</Link>
                );
            } else if (thumbnail == "appleMusic") {
                albumLinks.push(
                    <Link className="genLink btn" key={iter} to={albumReview.links[thumbnail]} target='_blank'><img className="albumLinks" src={"/images/icons/appleMusic.png"} alt="Apple Music Link"></img>Apply Music</Link>
                );
            }

            iter++;
        }

        // generate track details
        var trackDetails = [];
        for (let trackid in albumReview.tracks) {
            trackDetails.push(
                <iframe key={trackid} className="albumTrack" style={{borderRadius: '12px'}} src={'https://open.spotify.com/embed/track/' + albumReview.tracks[trackid].spot_id + "?utm_source=generator&theme=0"} width="100%" height="152" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            );
        }

        render = (
            <article className="albumReviewFull">
                <div className="albumInfo">
                    <span>{albumReview.album_name}</span>
                    <span>•</span>
                    <span>{albumReview.artist_name}</span>
                    <span>•</span>
                    <span className="albumRating">{albumReview.album_rating}</span>
                </div>
                <Link className="albumCover" to={"/albumReview/" + albumReview.ablum_review_id}>
                    <img className="albumCover" src={"/images/albumCovers/" + albumReview.album_name.toLowerCase() + ".jpeg"}/>
                </Link>
                <h3 className="releaseDate">
                    {albumReview.album_release_date}
                </h3>
                <p className='content'>{albumReview.album_review_long}</p>
                <h3 className="trackListHeader">Tracks</h3>
                <section className="trackList">
                    {trackDetails}
                </section>
                <h3 className="albumLinksHeader">Listen</h3>
                <section className="albumLinks">
                    {albumLinks}
                </section>
            </article>
        );

        // generate meta tags
        metatags = (
            <>
                <meta property="og:title" content={albumReview.album_name + " Review"} />
                <meta property="og:description" content={albumReview.album_review_title} />
                <meta property="og:image" content={"/images/link_share/" + albumReview.album_name.toLowerCase() + "_share.jpeg"} />
                <meta property="og:type" content="article" />
                <meta property="og:locale" content="en_US" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@museroads" />
                <meta name="twitter:title" content={albumReview.album_name + " Review"} />
                <meta name="twitter:description" content={albumReview.album_review_title} />
                <meta name="twitter:image" content={"/images/link_share/" + albumReview.album_name.toLowerCase() + "_share.jpeg"} />
            </>
        );
    }

    return (
        <>
            {metatags}
            {render}
            <Link className="genLink" style={{fontFamily: 'Namaku', fontSize: '2em', width: '100%', display: 'block', textAlign: 'center', marginBottom: '10px'}} to={"/"}>Explore More</Link>
        </>
    );
}

export default AlbumReview;

