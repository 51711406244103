// reviewer page

import './finalReviewer.css'

import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Utilities from '../js/utils';

function FinalReviewer() {

    // init vars
    var render = <h3>Unable to find album data</h3>;
    var renderedTBreakdown = [];

    // determine song and album id
    var args = window.location.pathname.split("/");
    var albumID = args[2];

    var [albumData, setAlbumData] = useState({});
    var [trackBreakdown, setTrackBreakdown] = useState([]);

    function generateFinalRating() {

    }

    function submitAlbumReview(event) {
        event.preventDefault();
    }

    function updateValue(event, metricName) {

        var newAlbumData = { ...albumData };
        newAlbumData[metricName] = event.target.value;

        setAlbumData(newAlbumData);
    }

    // get finalReviewData
    useEffect(() => {
        // get review data
        if(parseInt(albumID) != NaN && albumID != undefined) {
            axios.get('/api/finalReviewData/' + albumID).then((response) => {
                if (response.status == 200 && response.data.success == true && response.data.results > 0) {
                    console.log(response.data);
                    setAlbumData(response.data.result)
                }
            });
        }
    }, []);

    // generate track breakdown
    for(var x in albumData.tracks) {
        var quotes = [];
        for (var y in albumData.tracks[x].quotes) {
            quotes.push(<span key={y}>{parseInt(y) + 1}.{albumData.tracks[x].quotes[y]}</span>);
        }
        renderedTBreakdown.push(
            <section key={x} style={{display: 'flex', flexDirection: 'column', gap: '10px', border: '2px solid green', padding: '5px'}}>
                <span>Name: {albumData.tracks[x].name}</span>
                <span>Rating: {albumData.tracks[x].gen_rating}</span>
                <span>Track Review: </span>
                <span>{albumData.tracks[x].review}</span>
                <span>Quotes:</span>
                <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    {quotes}
                </div>
            </section>
        );
    }

    return (
        <>
            <form className='albumReview' onSubmit={submitAlbumReview}>
                { albumData.album_name ? <img className="albumCover" style={{maxWidth: '500px', width: '100%'}} src={"/images/albumCovers/" + albumData.album_name.toLowerCase() + ".jpeg"}/> : undefined }
                <h3>Rating {albumData.gen_rating}</h3>
                <textarea className="review" name="review" style={{width: '100%', margin: 'auto'}} placeholder='Enter text review' value={albumData.album_review == null ? "" : albumData.album_review } onChange={(e) => updateValue(e, "album_review")}/>
                <button type="submit">Submit</button>
                <h3>Track Breakdown:</h3>
                <div style={{height: '300px', overflowY: 'scroll', marginBottom: '20px'}}>
                    {renderedTBreakdown}
                </div>
            </form>
        </>
    );
}

export default FinalReviewer;