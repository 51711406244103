// Home component

// import css
import './home.css';

import React from "react";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';

function Home() {

    // init vars
    var render = [];

    var [albums, setAlbums] = useState([]);

    // fetch album data
    useEffect(() => {
        axios.get('/api/albumReviews').then((response) => {
            if (response.status == 200 && response.data.success == true && response.data.results > 0) {
                setAlbums(response.data.result);
            }
        })
    }, []);

    // generate loading render
    if (albums.length <= 0) {
        for(var i = 0; i < 3; i++){
            render.push(
                <article className="albumReview" key={i}>
                    <div className="albumInfo">
                        <div className='pendingContentBlock' style={{'width' : '100px', 'height': '30px'}}></div>
                        <span>•</span>
                        <div className='pendingContentBlock' style={{'width' : '100px', 'height': '30px'}}></div>
                        <span>•</span>
                        <div className='pendingContentBlock' style={{'width' : '100px', 'height': '30px'}}></div>
                    </div>
                    <div className="albumCover">
                        <div className='pendingContentBlock' style={{'width' : '100%', 'height': '200px'}}></div>
                    </div>
                    <div className="releaseDate">
                        <div className='pendingContentBlock' style={{'width' : '60%', 'height': '30px'}}></div>
                    </div>
                    <div className='content'>
                        <div className='pendingContentBlock' style={{'width' : '100%', 'height': '200px'}}></div>
                    </div>
                    <div className="albumLinks">
                        <div className='pendingContentBlock' style={{'width' : '60%', 'height': '30px'}}></div>
                    </div>
                </article>
            );
        }
    } else {
        for (let albumNum in albums) {
            // calc string cuttoff cound
            var strCutoffCount = 600;
            if (window.innerWidth > 480) {
                strCutoffCount = 1000;
            }

            // convert review into snippet
            if (albums[albumNum].album_review_long.length > 1000) {
                albums[albumNum].album_review_long =   <p className='content'> 
                                                {albums[albumNum].album_review_long.substring(0,strCutoffCount) + " "} 
                                                <Link className="genLink btn" style={{textWrap: 'nowrap'}} to={"/albumReview/" + albums[albumNum].ablum_review_id}> ...Read More</Link>
                                            </p>;
            }

            // generate thumbnails 
            var albumLinks = [];
            var iter = 0;
            for (let thumbnail in albums[albumNum].links) {
                if(thumbnail == "spotify") {
                    albumLinks.push(
                        <Link className="genLink btn" key={iter} to={albums[albumNum].links[thumbnail]} target='_blank'><img className="albumLinks" src={"/images/icons/spotify.png"} alt="Spotify Link"></img>Spotify</Link>
                    );
                } else if (thumbnail == "appleMusic") {
                    albumLinks.push(
                        <Link className="genLink btn" key={iter} to={albums[albumNum].links[thumbnail]} target='_blank'><img className="albumLinks" src={"/images/icons/appleMusic.png"} alt="Apple Music Link"></img>Apple Music</Link>
                    );
                }

                iter++;
            }

            // render content
            render.push(
                <article className="albumReview" key={albums[albumNum].ablum_review_id}>
                    <div className="albumInfo">
                        <span>{albums[albumNum].album_name}</span>
                        <span>•</span>
                        <span>{albums[albumNum].artist_name}</span>
                        <span>•</span>
                        <span className="albumRating">{albums[albumNum].album_rating}</span>
                    </div>
                    <Link className="albumCover" to={"/albumReview/" + albums[albumNum].ablum_review_id}>
                        <img className="albumCover" src={"/images/albumCovers/" + albums[albumNum].album_name.toLowerCase() + ".jpeg"}/>
                    </Link>
                    <h3 className="releaseDate">
                        {albums[albumNum].album_release_date}
                    </h3>
                    {albums[albumNum].album_review_long}
                    <section className="albumLinks">
                        {albumLinks}
                    </section>
                </article>
            );
        }
    }

    return (
        <>
            <section className="albumReviews">
                {render}
                <span style={{fontFamily: 'Namaku', fontSize: '2em', width: '100%', display: 'block', textAlign: 'center', marginTop: '10px'}}>more to come</span>
            </section>
            
        </>
    );
}

export default Home;