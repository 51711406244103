// reviewer page

import './albumReviewDir.css'

import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Utilities from '../js/utils';

function ReviewDir() {

    // determine song and album id
    var args = window.location.pathname.split("/");
    var albumID = args[2];
    var songID = args[3];
    var req = '';
    var render = [];
    var menuButtons = undefined;

    var [links, setLinks] = useState([]);

    if(parseInt(albumID) == NaN || albumID == undefined) {
        req = '/api/albums';
    } else if (parseInt(songID) == NaN || songID == undefined) {
        req = '/api/albums/' + albumID;

        menuButtons = (
            <a style={{}} href={"/albumReviewDir/"}>Back</a>
        );
    }

    // get all album
    useEffect(() => {
        // get review data
        axios.get(req).then((response) => {
            if (response.status == 200 && response.data.success == true && response.data.results > 0) {
                setLinks(response.data.result);
            }
        });
    }, []);

    // render albums
    for( var x in links) {
        if (links[x].track_number == undefined) {
            render.push(
                (
                    <section style={{"display": "flex", "gap": "10px"}}>
                        <Link key={x} target="_blank" to={'/albumReviewDir/' + links[x].id }>{links[x].name}</Link>
                        <span>{ links[x].published == 1 ? "Published" : "Unpublished"} </span> 
                    </section>
                )
            );
        } else {
            render.push(
                <Link key={x} target="_blank" to={'/reviewer/' + albumID + "/" + links[x].track_number}>{links[x].name}</Link>
            );
        }
    }

    return (
        <section className="reviewDir">
            {menuButtons}
            { links[0] != undefined && links[0].album_name != undefined ? <img className="albumCover" style={{maxWidth: '500px'}} src={"/images/albumCovers/" + links[0].album_name.toLowerCase() + ".jpeg"}/> : undefined}
            {render}
        </section>
    );
}

export default ReviewDir;